// layout container home media slider
.homeCallToActions {
  background-color: $primary-color;
  padding: 30px;
  
  width: calc(100vw);
  margin-left: calc((100vw - 1200px)/-2);
  
  padding: 30px calc(((100vw - 60px) - 1200px)/2);
  
  .page-manager-visible & {
    width: calc(100vw - 60px);
    margin-left: calc((100vw - 1260px)/-2);
    
    padding: 30px calc(((100vw - 60px) - 1200px)/2);
    
  }
  @media screen and (max-width:1260px) {
    .page-manager-visible & {
      width: 100%;
      margin-left: 0;
    }
  }
  @media screen and (max-width:1200px) {
    width: 100%;
    margin-left: 0;
  }
  
  // remove top & bottom borders on CTA's
  .sn-call-to-action:before,
  .sn-call-to-action:after {
    content: none;
  }
  // target only cta's that have the default style
  .sn-variation-default {
    .sn-call-to-action-overlay {
      
      background: rgba($primary-color,.75);
      
      
      opacity:1;
      transition:opacity 300ms ;
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      &:before {
        background: -moz-linear-gradient(top,  rgba(200,200,200,.25) 0%, rgba(90,90,90,.25) 50%, rgba(40,40,40,.25) 51%, rgba(0,0,0,.25) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(200,200,200,.25) 0%,rgba(90,90,90,.25) 50%,rgba(40,40,40,.25) 51%,rgba(0,0,0,.25) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(200,200,200,.25) 0%,rgba(90,90,90,.25) 50%,rgba(40,40,40,.25) 51%,rgba(0,0,0,.25) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        z-index: -1;
      }
      &:after {
        display: block;
        background: $primary-color;
        z-index: -2;
      }
      
      .sn-call-to-action-title {
        font-family: $fontOne;
        font-weight: bold;
        font-size: 28px;
        
        color: white !important;
        
        text-transform: uppercase;
        text-align: inherit;
        text-shadow: inherit;
        line-height: 1em;
        
        &:after {
          content: '';
          display: block;
          border-bottom: 1px solid white;
          padding-bottom: 5px;
          width: 20%;
          margin: 0 auto;
          transition: width 300ms ;
        }
        
      }
      .sn-call-to-action-subtitle,
      .sn-call-to-action-subtitle a,
      .sn-call-to-action-subtitle span {
        font-family: $fontOne;
        font-weight: normal;
        font-size: 16px;
        color: white;
        text-transform: uppercase;
        text-align: inherit;
        text-shadow: inherit;
        line-height: 1em;
      }
    }
    &:hover {
      
      
      .sn-call-to-action-title:after {
        width: 80%;
      }
      
    }
  }
  &.layout-2 {
    position: absolute;
    width: calc(100% - 45% - 50px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: 50px;
    padding:0 calc(((100% - 45%) - 33.33%)/2);
    background: transparent;
    @media only screen and (max-width: 690px){ right: 0; }
    .column {
      width: 100%;
      .edit_mode & {
        width: 33.33%;
      }
    }
    .sn-variation-default {
      height: 120px !important;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-right: 20px solid #c30000;
        border-bottom: 20px solid #c30000;
        border-left: 20px solid transparent;
        top: initial;
        left: initial;
        background: none;
        transition: border-width 200ms ease-out;
      }
      &:hover:before {
        border-width: 30px;
      }
      &:after {
        content: '';
        background: none;
        height: 0;
        width: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid #fff;
        bottom: 8px;
        right: 2px;
        left: initial;
        transition: bottom 200ms ease-out, right 200ms ease-out;
      }
      &:hover:after {
        bottom: 14px;
        right: 8px;
      }
    }
    @media screen and (max-width:1260px) {
      .sn-variation-default {
        height: calc(120px * .75) !important;
          .sn-call-to-action-overlay {
          .sn-call-to-action-title {
            font-size: calc(28px * .75);
          }
          .sn-call-to-action-subtitle,
          .sn-call-to-action-subtitle a,
          .sn-call-to-action-subtitle span {
            font-size: calc(16px * .75);
          }
        }
      }
    }
    @media screen and (max-width:1024px) {
      .sn-variation-default {
        height: calc(120px * .5) !important;
          .sn-call-to-action-overlay {
          .sn-call-to-action-title {
            font-size: calc(28px * .5);
          }
          .sn-call-to-action-subtitle,
          .sn-call-to-action-subtitle a,
          .sn-call-to-action-subtitle span {
            display: none;
          }
        }
      }
    }
    @media screen and (max-width: 690px) {
      position: relative;
      width: 100%;
      padding: 5%;
    }
  }

  // might be for layout version 2
  // .edit_mode & {
  //   width: 100%;
  //   display: initial;
  //   flex-direction: initial;
  // }
}
